 /* Hide scrollbar for Chrome, Safari and Opera */
 .hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.texas-li {

}

.texas-li::before {
  content: "";
  @apply inline-block bg-slate-700 w-2 h-2 mr-2 rounded-full
}

